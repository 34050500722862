// import sayHello from './lib/sayHello.js';
import './lib/common.js';
import {TimelineMax} from 'gsap'; // Анимация
import fancybox from '@fancyapps/fancybox'; // Модальные окна
import owlCarousel from 'owl.carousel'; // Карусель
import mixitup from 'mixitup'; // Сортировка галереи
import maskPlugin from 'jquery-mask-plugin'; // Маска телефона
import lazyload from 'jquery-lazyload'; // Маска телефона

$('img.lazyload').lazyload({
  threshold : 1080,
  effect : 'fadeIn'
});

$('[data-click]').on('click', function() {
  let click = $(this).data('click');
  fbq('trackCustom',click);
  yaCounter54652267.reachGoal(click);
});

// var options =  {
//   onKeyPress: function(cep, e, field, options) {
//     var masks = ['+0 (000) 000-00009', '+00 (000) 000-0000'];
//     var mask = (cep.length>17) ? masks[1] : masks[0];
//     $('.phone-mask').mask(mask, options);
//   }};

// $('.phone-mask').mask('+0 000 000-0000', options);

// Маска телефона, если телефон не заполнен до конца,
// то при нажатии куда либо сбрасывает введенное в инпут
let maskPhone = '+38 000 000-00-00';
$('input[name=phone]').mask(maskPhone).focusout(function() {
  let p = $(this);
  if (p.val().length < maskPhone.length) {
    p.val('');
  }
});

// First Animation
let tl = new TimelineMax();

let windowHeight = $(window).height();

tl
  .to('.loader', 0.25, {opacity: 0, onComplete: function() {$('.loader').remove();}}, 0.5)
  .fromTo('.header-bg', 1, {height: 0}, {height: 1080})
  .fromTo('.header-top--logo', 0.5, {y:-30,opacity: 0}, {y: 0, opacity: 1}, '-=1')
  .fromTo('.header-top--right', 0.5, {y:-30,opacity: 0}, {y: 0, opacity: 1}, '-=.9')
  .fromTo('.header-nav--btn', 0.5, {y:-30,opacity: 0}, {y: 0, opacity: 1}, '-=.8')
  .fromTo('.header-content', 0.5, {y:-30,opacity: 0}, {y: 0, opacity: 1}, '-=.5')
  .staggerFromTo('.header-socials .stagger', 0.5, {x:-30,opacity: 0}, {x: 0, opacity: 1}, -0.05, '-=.3')
  .staggerFromTo('.header-scroll .stagger', 0.5, {y:-30,opacity: 0}, {y: 0, opacity: 1, onComplete: animate}, -0.1, '-=.3')
  .fromTo('section', 0.5, {y:-30,opacity: 0}, {y: 0, opacity: 1}, '-=1');

function animate() {
  $('.header-scroll').addClass('animate');
};

// Gallery
if ($('div').is('.gallery')) {
  let mixer = mixitup('.gallery', {
    animation: {
      effects: 'fade translateY(10px)',
      effectsOut: 'fade',
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)'
    }
  });
} 

// Скролинг по лендингу при нажатии на ссылку
$('.scroll-to').on('click', function(e) {
  e.preventDefault();
  var id  = $(this).attr('href'),
    top = $(id).offset().top;
  $('body,html').animate({scrollTop: top}, 1500);
});	

// Menu
$('.header-nav--menu a').append('<span>').append('<span>').append('<span>').append('<span>');

$('.menu-btn, .header-nav--menu .scroll-to').on('click', function(e) {
  e.preventDefault;
  $('.menu-btn').toggleClass('menu-btn__active');
  $('.header-nav--menu').toggleClass('active');
  tl
  	.staggerFromTo('.header-nav--menu li', 0.5, {y:30,opacity: 0}, {y: 0, opacity: 1}, -0.05, '=.25');
  $('body').toggleClass('hold');
});

// LANG
$('.header-lang-link').on('click', function(e) {
  e.preventDefault;
  $(this).toggleClass('active');
  $('.header-lang-list').toggleClass('active');
  return false;
});

// More
$('.read-more').on('click', function() {
  let moreId = $(this).data('more'),
    moreText = $('#' + moreId).text();

  console.log(moreText);
  $(this).parent().append('<span class="moretext">' + moreText + '</span>');
  $(this).remove();

});

// Calculate
let calc = () => {
  let slider = $('label[range]'),
    range = $('input[type=range]'),
    value = $('.range-value');

  slider.each(function() {

    value.each(function() {
      var value = $(this).next().attr('value');
      $(this).html(value);
    });

    range.on('input', function() {
    	let weight = + $('#weightInputId').val(),
        age = + $('#ageInputId').val(),
        gConst = age / weight + Math.cos(age / weight);

    	if ( $('.grammOut').hasClass('show') === false ) {
    		$('.grammOut').addClass('show');
    	}
    	$(this).prev(value).html(this.value);
    	$('#grammOutId').text( Math.floor((weight + age) * gConst + 50 * gConst));
    	
    });
  });
};

calc();

// Modals
$('[data-fancybox]').fancybox({
  loop: true,
  autoFocus: false,
  touch: {
    vertical: false
  },
  buttons: [
    'zoom',
    'close'
  ],
});

$('[data-check]').on('click', function() {
  let th = $(this),
    modal = th.attr('href'),
    check = th.data('check');
  $(modal).find('input[name=check]').each(function() {
  	let ch = $('input[name=check]');
    if ($(this).val() === check) {
      $(this).prop( 'checked', true );
    }
  });
});

// Вставка в форму комментария от нажатой кнопки
// открывающей модальное окно
$('.button[href="#form"]').on('click', function() {
  let button = $(this).data('button');
  $('form .hidden input[name="button"]').remove();
  $('form .hidden').append('<input type="hidden" name="button" value="'+button+'">');
  if ($(this).hasClass('button-calc')) {
    $('form .hiddenCalc').empty();
  	let age = $('#calc').find('#ageInputId').val(),
  		weight = $('#calc').find('#weightInputId').val(),
      gramm = $('#grammOutId').text();
  		$('form .hiddenCalc').append('<input type="hidden" name="age" value="'+age+'"><input type="hidden" name="weight" value="'+weight+'"><input type="hidden" name="gramm" value="'+gramm+'">');
  }
});



// OWL
$('.reviews').owlCarousel({
  loop: true,
  margin: 20,
  nav: true,
  navText: ['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
  lazyLoad: true,
  dots: true,
  responsive:{
    0:{items:1,lazyLoadEager:1},
    600:{items:2,lazyLoadEager:2},
    900:{items:3,lazyLoadEager:3},
    1368:{items:4,lazyLoadEager:4}
  }
});


// Utm
// This script added utm hidden input in form 
var params = window.location.search.replace('?','').split('&').reduce(
  function(p,e) {
    var a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return p;
  }, {});

$('form').each(function() { 
  let th = $(this).find('.utm');
  $.each(params, function(key, value) {
    th.append('<input name="'+key+'" value="'+value+'" hidden>');
  });
});


// Form
$(function() {
  $('form').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    let form = $(this),
      btn = form.find('.button-submit');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.field-input').addClass('empty-field');

    // Функция проверки полей формы
    function checkInput() {
      form.find('.field-input').each(function() {
        if($(this).val() !== '') {
          // Если поле не пустое удаляем класс-указание
          $(this).removeClass('empty-field');
        } else {
          // Если поле пустое добавляем класс-указание
          $(this).addClass('empty-field');
        }
      });
    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
      form.find('.empty-field').parent().addClass('empty-field');
      // Через полсекунды удаляем подсветку
      setTimeout(function() {
        form.find('.empty-field').parent().removeClass('empty-field');
      },1000);
    }

    // Функция отправки формы 
    // Нужно написать ajax функцию отправки формы
    function fromSubmit() {
      var phone = form.find('input[name=phone]').val();
      btn.prop('disabled', true);

      // $.ajax({
      //   type: 'POST',
      //   url: '/core/components/gitmodx/elements/send_lead/send_lead.php', //Change
      //   data: form.serialize()
      // }).done(function() {
      //   // alert ('Ваши данные отправлены');
      //   // console.log("Data:"+d)
      //   // Функция после отправки формы
      //   // Отслеживание лидов
      //   // dataLayer.push({'event':'send_request'});
      //   // yaCounter34842275.reachGoal('lead_form_order');
      //   // fbq('track','Lead');
      // });

      $(document).on('af_complete', function(event, response) {

        var form = response.form;

        form.trigger('reset');
        btn.prop('disabled', false);
        
        $.fancybox.close();

        $.fancybox.open({
          src  : '#thanks',
          type : 'inline',
          buttons: [
            'close'
          ],
          afterLoad: function() {
            $('.check-phone').empty().append(phone);
          }
        });

        // Отслеживание лидов
        dataLayer.push({'event':'send_request'});
        yaCounter53814523.reachGoal('lead_form_order');
        fbq('track','Lead');
      });

    }

    // Проверка в режиме реального времени
    setInterval(function() {
      // Запускаем функцию проверки полей на заполненность
      checkInput();
      // Считаем к-во незаполненных полей
      let sizeEmpty = form.find('.empty-field').length;

      // Вешаем условие-тригер на кнопку отправки формы
      if(sizeEmpty > 0) {
        if(btn.hasClass('disabled')) {
          return false;
        } else {
          btn.addClass('disabled');
        }
      } else {
        btn.removeClass('disabled');
      }
    },500);

    // Событие клика по кнопке отправить
    btn.click(function() {
      if($(this).hasClass('disabled')) {
        // подсвечиваем незаполненные поля и форму не отправляем, если есть незаполненные поля
        lightEmpty();
        return false;
      } else {
        // Все хорошо, все заполнено, отправляем форму
        form.submit();
        fromSubmit();
      }
    });
  });
});





// RANGE
// var rangeSlider = () => {
//   var slider = $('label[range]'),
//     range = $('input[type=range]'),
//     value = $('.range-value');
		
//   slider.each(function() {

//     value.each(function() {
//       var value = $(this).next().attr('value');
//       $(this).html(value);
//     });

//     range.on('input', function() {
//       $(this).prev(value).html(this.value);
//     });
//   });
// };

// rangeSlider();
